/**
 * Adds all functionality to forms.
 *
 * @module feature/Forms
 */
import 'bootstrap/js/dist/collapse.js';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.js';
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.nl.min.js';
import './lib/clockpicker.js';

/**
 * Base form functionality.
 *
 * TODO: Fix quick and dirty code!
 * @requires bootstrap/collapse
 * @requires bootstrap-datepicker
 * @requires clockpicker
 * @memberof module:feature/Forms
 * @version 0.8.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class Form {
    /**
     * Sets up form element, togglers and collapsibles.
     * @param {jqueryelement} $element Current form node.
     */
    constructor($element) {
        this.$element = $element;
        this.togglers = [];
        this.collapsibles = [];
    }

    /**
     * Initializes all form elements.
     */
    init() {
        // // init togglers
        // this.togglers = {
        //     address: this.$element.find('#toggleraddress'),
        //     postbus: this.$element.find('#togglerpostbus')
        // };

        // // init collapsibles
        // this.collapsibles = {
        //     address: this.$element.find('.collapse.address').collapse(),
        //     postbus: this.$element.find('.collapse.postbus').collapse('hide')
        // };

        // // events
        // this.togglers.address.on('change.collapse', this.handleCollapseToggle.bind(this));
        // this.togglers.postbus.on('change.collapse', this.handleCollapseToggle.bind(this));

        // honeypot fields hidden
        $('.attribute-field, .regular-form-field').addClass('form-masked');

        // file upload fields
        let fileInputs = this.$element.find('input[type=file]');
        if (fileInputs.length > 0) {
            for (let i = 0; i < fileInputs.length; i++) {
                let $placeholder = $(fileInputs[i]).parent().find('label');
                if ($placeholder.length > 0) {
                    const currentLabel = $placeholder.text();
                    $(fileInputs[i]).on('change', () => {
                        if ($(fileInputs[i]).val()) {
                            $placeholder.text($(fileInputs[i]).val().split('\\').reverse()[0]);
                        } else {
                            $placeholder.text(currentLabel);
                        }
                    });
                }
            }
        }

        $('.datepicker--future').datepicker({
            format: 'dd/mm/yyyy',
            startDate: 'now()',
            clearBtn: true,
            language: 'nl',
            daysOfWeekDisabled: '0,6',
            orientation: 'top right',
            autoclose: true,
        });

        $('.datepicker--dob').datepicker({
            format: 'dd/mm/yyyy',
            clearBtn: true,
            language: 'nl',
            orientation: 'top right',
            autoclose: true,
        });

        $('.timepicker').clockpicker({
            placement: 'top',
            align: 'right',
            donetext: 'Ok',
            autoclose: true,
        });

        return this;
    }

    // /**
    //  * Handles toggling of form rows.
    //  * @param {event} e jQuery event.
    //  */
    // this.handleCollapseToggle = function(e) {
    //     if ($(e.target).attr('id') === 'togglerpostbus') {
    //         this.collapsibles.postbus.collapse('show');
    //         this.collapsibles.address.collapse('hide');
    //     } else {
    //         this.collapsibles.postbus.collapse('hide');
    //         this.collapsibles.address.collapse('show');
    //     }
    // };
}

ComponentHandler.register({
    constructor: Form,
    classAsString: 'Form',
    cssClass: 'js-form',
});

export default Form;
